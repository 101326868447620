<template>
  <div class="gallery">
    <div class="gallery__inner">
      <Title :label="$t('fewpictures')" />
      <div v-viewer="viewerConfig" class="gallery__list">
        <div
          class="gallery__thumbnail"
          v-for="(item, index) in gallery"
          :key="`gallery-item-${index}`"
        >
          <img
            :src="thumbnailUrl(item.picture)"
            :data-source="fullscreenUrl(item.picture)"
            :alt="imageLabel(item)"
            :title="imageLabel(item)"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      viewerConfig: {
        inline: false,
        button: true,
        navbar: true,
        // eslint-disable-next-line
        title: [1, (image, imageData) => image.alt],
        toolbar: {
          zoomIn: 4,
          zoomOut: 4,
          oneToOne: 4,
          reset: 0,
          prev: 4,
          play: {
            show: 4,
            size: 'large',
          },
          next: 4,
        },
        tooltip: false,
        movable: false,
        zoomable: false,
        rotatable: 0,
        scalable: false,
        transition: true,
        fullscreen: true,
        keyboard: true,
        url: 'data-source',
      },
      gallery: [
        {
          picture: 'gallery26',
          label_fr: 'Valse viennoise au concert du nouvel an',
          label_de: 'Waltzer beim Neujahrkonzert',
        },
        { picture: 'gallery1', label_fr: '', label_de: '' },
        { picture: 'gallery2', label_fr: '', label_de: '' },
        { picture: 'gallery3', label_fr: '', label_de: '' },
        // { picture: 'gallery4', label_fr: '', label_de: '' },
        // { picture: 'gallery5', label_fr: '', label_de: '' },
        // { picture: 'gallery6', label_fr: '', label_de: '' },
        { picture: 'gallery7', label_fr: '', label_de: '' },
        // { picture: 'gallery8', label_fr: '', label_de: '' },
        // { picture: 'gallery9', label_fr: '', label_de: '' },
        // { picture: 'gallery10', label_fr: '', label_de: '' },
        // { picture: 'gallery11', label_fr: '', label_de: '' },
        // { picture: 'gallery12', label_fr: '', label_de: '' },
        // { picture: 'gallery13', label_fr: '', label_de: '' },
        // { picture: 'gallery14', label_fr: '', label_de: '' },
        // { picture: 'gallery15', label_fr: '', label_de: '' },
        // { picture: 'gallery16', label_fr: '', label_de: '' },
        { picture: 'gallery17', label_fr: '', label_de: '' },
        // { picture: 'gallery18', label_fr: '', label_de: '' },
        // { picture: 'gallery19', label_fr: '', label_de: '' },
        { picture: 'gallery20', label_fr: '', label_de: '' },
        { picture: 'gallery21', label_fr: '', label_de: '' },
        { picture: 'gallery22', label_fr: '', label_de: '' },
        { picture: 'gallery23', label_fr: '', label_de: '' },
        { picture: 'gallery24', label_fr: '', label_de: '' },
        { picture: 'gallery25', label_fr: '', label_de: '' },
      ],
    };
  },

  methods: {
    thumbnailUrl(picture) {
      return (
        `https://res.cloudinary.com/zigali/image/upload/c_fill,g_auto,h_255,w_380/${
          picture}`
      );
    },
    fullscreenUrl(picture) {
      return (
        `https://res.cloudinary.com/zigali/image/upload/c_scale,w_1200/${
          picture}`
      );
    },
    imageLabel(item) {
      return item.label_fr;
    },
  },
};
</script>

<style lang="scss">
@import '~viewerjs/dist/viewer.min.css';
.gallery {
  background: #ececec;
  margin-top: -90px;
  padding: 130px 15px 120px;
  box-shadow: 0 0 5px 0 rgba(0,0,0,0.75);
  position: relative;
  z-index: 2;

  &__inner {
    max-width: 1140px;
    margin: 0 auto;
  }

  &__list {
    margin-top: 80px;
    display: grid;
    align-items: center;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    grid-gap: 30px;
  }

  &__thumbnail {
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      max-width: 100%;
    }
  }
}
</style>
