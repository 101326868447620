<template>
  <div class="footer">
    &copy; 2021 - Nakiane
    <div v-if="$i18n.locale === 'de'" @click="showLegal">- Impressum</div>
  </div>
</template>

<script>
export default {
  methods: {
    showLegal() {
      this.$emit('displaylegal');
    },
  },
};
</script>

<style lang="scss">
.footer {
  width: 100%;
  background: #343a40;
  color: #c3c3c3;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px;

  div {
    font-weight: bold;
    text-decoration: none;
    margin-left: 5px;
    cursor: pointer;
  }
}
</style>
