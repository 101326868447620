import Vue from 'vue';
import Viewer from 'v-viewer';
import Vuelidate from 'vuelidate';
import App from './App.vue';
import i18n from './locale';
import Title from './components/Title.vue';

Vue.component('Title', Title);
Vue.use(Viewer);
Vue.use(Vuelidate);

Vue.config.productionTip = false;

new Vue({
  render: (h) => h(App),
  i18n,
}).$mount('#app');
