import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

const messages = {
  de: {
    whoarewe: 'Wer sind wir ?',
    biography1:
      'Im August 2010 fällten wir die Entscheidung im Tanzturniersport zu starten. Im November 2010 tanzten wir unser erstes Turnier der 5 Standardtänze: Langsamer Walzer, Tango, Wiener Walzer, Slowfox und Quick Step. Mit diesem ersten Turnier war der Startschuss gefallen um hierin Erfolge zu verzeichnen.',
    biography2:
      'Nach nur 88 Turnieren erreichten wir die höchste Leistungsklasse im Amateur Tanzsport, die Klasse S. Mit mittlerweile insgesamt über 100 Turnieren haben wir in über 60 Podiumsplätzen 15 mal Gold geholt.<br />1 x Landesmeister und 1 x Vizelandesmeister von Baden Württemberg.',
    biography3:
      'Seit 2013 starten sie auch bei internationalen Turnieren und tanzten z.B. in Frankreich unter den 15 Besten des Landes.',
    biography4:
      'Neben den Tanzturnieren widmen sie ihre Zeit sehr gerne auch dem Showtanz, wie z.B. bei Konzerten, Veranstaltungen mit Senioren und von Verbänden.',
    fewpictures: 'Einige Bühnenauftritte',
    contactus: 'Kontaktiere uns',
    name: 'Name',
    email: 'Email',
    message: 'Nachricht',
    required: 'Dieses Feld ist erforderlich',
    validemail: 'Dies ist keine gültige e-mail Adresse',
    send: 'Senden',
  },
  fr: {
    whoarewe: 'Qui sommes-nous ?',
    biography1:
      'En août 2010, les deux danseurs ont décidé de se lancer dans les tournois de danse. En novembre 2010, ils participent à leur premier tournoi parmi les 5 danses standards: Valse Lente, Tango, Valse Viennoise, Slowfox et Quick Step. Avec ce premier tournoi, le signal de départ était donné pour le succès.',
    biography2:
      "Après seulement 88 tournois, Siggi et Alain ont atteint la classe de performance la plus élevée en danse amateur, la classe S.<br />Avec plus de 100 tournois au total, ils ont remporté 15 médailles d'or sur 60 podiums. 1 fois champion national et 1 fois vice-champion du Bade Wurtemberg.",
    biography3:
      'Depuis 2013, ils participent également aux tournois internationaux.',
    biography4:
      "Outre les tournois, ils aiment aussi consacrer leur temps à la danse, par exemple lors de concerts, d'événements avec des personnes âgées et des associations.",
    fewpictures: 'Quelques représentations',
    contactus: 'Contactez-nous',
    name: 'Nom',
    email: 'Email',
    message: 'Message',
    required: 'Ce champ est obligatoire',
    validemail: 'Ceci n\'est pas une adresse email valide',
    send: 'Envoyer',
  },
};

const i18n = new VueI18n({
  locale: 'de',
  messages,
});

export default i18n;
