<template>
  <div class="legal" @click.self="close">
    <div class="legal__inner">
      <h1>Impressum</h1>
      <h2>ZIGALI</h2>
      <p>Gesellschafter:<br />Alain Blessig<br />Sigrid Heusel</p>
      <p>
        36 rue Principale<br />67480 Leutenheim<br />Frankreich<br />E-Mail: admin@zigali.de<br />
        Tel.: +33 7 83 60 27 52 (deutsch)<br />
      </p>
      <p><a href="https://www.zigali.de">www.zigali.de</a></p>

      <h3>Disclaimer – rechtliche Hinweise</h3>

      <h4>Haftung für Inhalte</h4>

      <p>
        Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach
        den allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter
        jedoch nicht verpflichtet, übermittelte oder gespeicherte fremde Informationen zu überwachen
        oder nach Umständen zu forschen, die auf eine rechtswidrige<br />
        Tätigkeit hinweisen. Verpflichtungen zur Entfernung oder Sperrung der Nutzung von
        Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt. Eine diesbezügliche
        Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung
        möglich. Bei Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese Inhalte
        umgehend entfernen.
      </p>

      <h4>Haftung für Links</h4>

      <p>
        Unser Angebot enthält Links zu externen Webseiten Dritter, auf deren Inhalte wir keinen
        Einfluss haben. Deshalb können wir für diese fremden Inhalte auch keine Gewähr übernehmen.
        Für die Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber
        der Seiten verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der Verlinkung
        auf mögliche Rechtsverstöße überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt der
        Verlinkung nicht erkennbar. Eine permanente inhaltliche Kontrolle der verlinkten Seiten
        ist jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei
        Bekanntwerden von Rechtsverletzungen werden wir derartige Links umgehend entfernen.
      </p>

      <h4>Urheberrecht</h4>

      <p>
        Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen
        dem deutschen Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art
        der Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der schriftlichen
        Zustimmung des jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser Seite sind
        nur für den privaten, nicht kommerziellen Gebrauch gestattet. Soweit die Inhalte auf
        dieser Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte Dritter
        beachtet. Insbesondere werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie
        trotzdem auf eine Urheberrechtsverletzung aufmerksam werden, bitten wir um einen
        entsprechenden Hinweis.<br />
        Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen.
      </p>

      <p>Quellenangabe: <a href="https://www.e-recht24.de" target="_blank">eRecht24</a></p>

      <h4>EU-Verordnung zu Online-Rechtsgeschäften</h4>

      <p>
        Auf der Grundlage der EU-Verordnung Nr. 524/2013 hat die EU-Kommission auf europäischer
        Ebene eine interaktive Website (OS-Plattform) bereit gestellt. Diese dient der Beilegung
        außergerichtlicher Streitigkeiten aus Online-Rechtsgeschäften.
      </p>
      <p>
        Sie finden diesen Service hier: https://webgate.ec.europa.eu/odr/main/?event=main.home.show
      </p>

      <h4>Bildnachweis</h4>
      <p>Private und öffentliche Bilder / Videos</p>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    close() {
      this.$emit('closed');
    },
  },
};
</script>

<style lang="scss">
.legal {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1000;
  background: rgba(#000000, .8);
  display: flex;
  justify-content: center;
  align-items: center;

  &__inner {
    background: #ffffff;
    border-radius: 15px;
    padding: 15px;
    max-width: 90%;
    max-height: 90%;
    overflow-y: auto;

    h1 {
      font-size: 23px;
      margin-bottom: 20px;
      text-transform: uppercase;
      font-weight: bold;
      text-decoration: underline;
    }

    h2 {
      font-size: 21px;
      margin-bottom: 20px;
      font-weight: bold;
    }

    h3 {
      font-size: 19px;
      margin-bottom: 20px;
      font-weight: bold;
    }

    h4 {
      font-size: 17px;
      margin-bottom: 20px;
      text-decoration: underline;
    }

    p {
      margin-bottom: 10px;
    }
  }
}
</style>
