<template>
  <div class="contact">
    <div class="contact__inner">
      <div class="contact__main">
        <div class="contact__form">
          <Title :label="$t('contactus')" />
          <hr />
          <div class="contact__form--field" :class="{invalid: hasError('name')}">
            <label for="name" :class="{shown: labels.showNameLabel}">{{ $t('name') }}</label>
            <input
              name="name"
              id="name"
              type="text"
              v-model="form.name"
              :placeholder="$t('name')"
              @focus="inputFocusHandler('name', true)"
              @blur="inputFocusHandler('name', false)"
            />
            <div class="error" v-if="hasError('name')">
              <span v-if="!$v.form.name.required">{{ $t('required') }}</span>
            </div>
          </div>
          <div class="contact__form--field" :class="{invalid: hasError('email')}">
            <label for="email" :class="{shown: labels.showEmailLabel}">{{ $t('email') }}</label>
            <input
              name="email"
              id="email"
              type="email"
              v-model="form.email"
              :placeholder="$t('email')"
              @focus="inputFocusHandler('email', true)"
              @blur="inputFocusHandler('email', false)"
            />
            <div class="error" v-if="hasError('email')">
              <span v-if="!$v.form.email.required">{{ $t('required') }}</span>
              <span v-if="!$v.form.email.email">{{ $t('validemail') }}</span>
            </div>
          </div>
          <div class="contact__form--field" :class="{invalid: hasError('message')}">
            <label for="message" :class="{shown: labels.showMessageLabel}">
              {{ $t('message') }}
            </label>
            <textarea
              name="message"
              id="message"
              v-model="form.message"
              :placeholder="$t('message')"
              rows="6"
              @focus="inputFocusHandler('message', true)"
              @blur="inputFocusHandler('message', false)"
            ></textarea>
            <div class="error" v-if="hasError('message')">
              <span v-if="!$v.form.message.required">{{ $t('required') }}</span>
            </div>
          </div>
          <button
            class="contact__form--cta"
            @click="process"
          >
            {{ $t('send') }}
          </button>
        </div>
        <div class="contact__coordinates">
          <img
            src="../assets/map.jpg"
            :alt="$t('contactus')"
            :title="$t('contactus')"
            class="contact__map"
          />
          <div class="contact__form--address">
            ZIGALI<br />
            36 Rue Principale, 67480 Leutenheim<br />
            +33(0)7 83 60 27 52
            <span v-if="$i18n.locale === 'de'">(deutsch)</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { required, email } from 'vuelidate/dist/validators.min';

export default {
  data() {
    return {
      submitted: false,
      form: {
        name: null,
        email: null,
        message: null,
      },
      labels: {
        showNameLabel: false,
        showEmailLabel: false,
        showMessageLabel: false,
      },
    };
  },

  methods: {
    inputFocusHandler(field, on) {
      const capitalized = field.charAt(0).toUpperCase() + field.substr(1);
      if (on) {
        this.labels[`show${capitalized}Label`] = true;
      } else {
        this.labels[`show${capitalized}Label`] = this.form[field] !== null && this.form[field] !== '';
      }
    },
    hasError(field) {
      return this.submitted && this.$v.form[field].$invalid;
    },
    process() {
      this.submitted = true;
    },
  },

  validations: {
    form: {
      name: { required },
      email: { required, email },
      message: { required },
    },
  },
};
</script>

<style lang="scss">
.contact {
  z-index: 1;
  background: linear-gradient(to right, rgba(255, 255, 255, .4), rgba(255, 255, 255, .7)),
    url("../assets/contact-bg.jpg");
  background-size: cover;
  padding: 70px 0;

  &__inner {
    max-width: 1140px;
    margin: 0 auto;
  }

  &__main {
    margin-top: 70px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }

  &__form {
    background: rgba(255, 255, 255, .7);
    padding: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;

    hr {
      width: 100%;
    }

    &--address {
      font-size: 25px;
      line-height: 40px;
      margin-top: 20px;
      text-align: right;
    }

    &--field {
      position: relative;
      margin: 20px 0;
      width: 100%;

      input, textarea {
        padding: 10px;
        border: 1px solid #ececec;
        background: #ffffff;
        width: 100%;
      }

      label {
        font-size: 13px;
        opacity: 0;
        position: absolute;
        top: 0;
        left: 2px;
        transition: all .2s;

        &.shown {
          top: -13px;
          opacity: 1;
        }
      }

      .error {
        font-size: 12px;
        position: absolute;
        left: 2px;
        bottom: 0;
        opacity: 0;
      }

      &.invalid {
        color: #8c1717;

        input, textarea {
          border: 1px solid #8c1717;
        }

        .error {
          opacity: 1;
          bottom: -14px;
        }
      }
    }

    &--cta {
      outline: none;
      padding: 10px 15px;
      border: 2px solid #42b983;
      color: #42b983;
      transition: all .3s;
      cursor: pointer;

      &:hover {
        background: #42b983;
        color: #ffffff;
      }
    }
  }
}

@media screen and (max-width: 1023px) {
  .contact {
    &__main {
      flex-direction: column;
      align-items: center;
    }

    &__coordinates {
      padding: 15px;
      width: 100%;
      display: flex;
      flex-direction: column-reverse;
    }

    &__map {
      width: 100%;
      margin-top: 30px;
    }
  }
}
</style>
