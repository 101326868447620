<template>
  <div class="hero">
    <div class="hero__logo">
      <img
        src="../assets/logo.png"
        alt="Zigali"
        title="Zigali"
        class="hero__logo--img animate__animated animate__fadeInUp"
      />
      <img
        :src="subtitles[$i18n.locale]"
        alt="École de danse"
        title="École de danse"
        class="hero__logo--subtitle animate__animated animate__fadeInUp"
      />
      <img
        src="../assets/show_subtitle.svg"
        alt="École de danse"
        title="École de danse"
        class="hero__logo--show-subtitle animate__animated animate__fadeInUp"
      />
    </div>
  </div>
</template>

<script>
import subtitleDe from '../assets/subtitle_de.svg';
import subtitleFr from '../assets/subtitle_fr.svg';

export default {
  components: {},

  data() {
    return {
      subtitles: {
        fr: subtitleFr,
        de: subtitleDe,
      },
    };
  },
};
</script>

<style lang="scss">
.hero {
  width: 100%;
  height: 98vh;
  background-size: cover;
  background: linear-gradient(to right, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.1)),
    url("../assets/bg.jpg");
  background-position: 75% 10%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 10%;

  &__logo {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: -60px;

    &--img {
      height: 500px;
    }

    &--subtitle {
      margin-top: -170px;
      height: 100px;
      animation-delay: 0.3s;
    }

    &--show-subtitle {
      height: 60px;
      margin-left: 200px;
      animation-delay: 0.6s;
    }
  }
}

@media screen and (max-width: 767px) {
  .hero {
    &__logo {
      width: 100%;

      &--img,
      &--subtitle {
        width: 100%;
      }

      &--img {
        height: initial;
      }

      &--subtitle {
        margin-top: -110px;
      }
    }
  }
}
</style>
