<template>
  <VuePerfectScrollbar class="main">
    <Hero />
    <Presentation />
    <Gallery />
    <Contact />
    <Footer @displaylegal="displayLegal" />
    <Legal
      v-if="showLegal"
      @closed="showLegal = false"
    />
  </VuePerfectScrollbar>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar';
import Hero from './components/Hero.vue';
import Presentation from './components/Presentation.vue';
import Gallery from './components/Gallery.vue';
import Contact from './components/Contact.vue';
import Footer from './components/Footer.vue';
import Legal from './components/Legal.vue';

export default {
  components: {
    Hero, Presentation, Gallery, Contact, Footer, Legal, VuePerfectScrollbar,
  },

  data() {
    return {
      showLegal: false,
    };
  },

  methods: {
    displayLegal() {
      this.showLegal = true;
    },
  },

  mounted() {
    if (window.location.host === 'zigali.de') {
      this.$i18n.locale = 'de';
    } else {
      this.$i18n.locale = 'fr';
    }
  },
};
</script>

<style lang="scss">
@import "~normalize.css/normalize.css";
@import "~reset.css/reset.css";
@import "~animate.css/animate.min.css";
@import url("https://fonts.googleapis.com/css2?family=Krub:wght@400;700&display=swap");

html,
body,
* {
  box-sizing: border-box;
  font-family: "Krub", sans-serif;
}

.main {
  position: relative;
  width: 100%;
  height: 100vh;
}

.viewer-backdrop {
  background-color: rgba(0,0,0,.8);
}

.viewer-title {
  color: #ffffff;
}
</style>
