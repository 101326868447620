<template>
  <h2 class="title">{{ label }}</h2>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: null,
    },
  },
};
</script>

<style lang="scss">
.title {
  font-size: 45px;
  position: relative;
  margin-bottom: 40px;
  text-align: center;

  &:after {
    height: 2px;
    width: 80px;
    content: "";
    position: absolute;
    background: #30688a;
    bottom: -20px;
    left: 50%;
    transform: translateX(-50%);
  }
}
</style>
